import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {
  API_URL_PREFIX,
  BuildingDto,
  GetSectionVariantsRequestDto,
  GetSectionVariantsResponseDto,
  LayoutSectionsGenerationStatus,
} from '@generation-api-v2/util';
import {SectionDto} from '@layout-api-v2/util/dto';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LayoutSectionsApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getSectionVariants(body: GetSectionVariantsRequestDto): Observable<GetSectionVariantsResponseDto> {
    // TODO: Вернуть наш запрос когда он заработает
    return this.http.post<GetSectionVariantsResponseDto>(
      `${this.gatewayUrl}/layout/v2/Variants/section-variants`,
      body,
    );
    // return this.http.post<GetSectionVariantsResponseDto>(
    //   `${this.gatewayUrl}/${this.apiPrefix}/LayoutSections/section-variants`,
    //   body,
    // );
  }

  getTopologyImageUrl(topologyId: string): string {
    return `${this.gatewayUrl}/${this.apiPrefix}/LayoutSections/topology/${topologyId}/image`;
  }

  getVariantStoreyImageUrl(gridId: string, storeyId: string): string {
    return `${this.gatewayUrl}/${this.apiPrefix}/LayoutSections/grid/${gridId}/storey/${storeyId}/image`;
  }

  getSectionVariant(variantId: string): Observable<SectionDto> {
    return this.http.get<SectionDto>(`${this.gatewayUrl}/${this.apiPrefix}/LayoutSections/section/${variantId}`);
  }

  getStatus(generationTaskId: string): Observable<LayoutSectionsGenerationStatus> {
    return this.http.get<LayoutSectionsGenerationStatus>(
      `${this.gatewayUrl}/${this.apiPrefix}/LayoutSections/${generationTaskId}/status`,
    );
  }

  getBuilding(variantIds: string[]): Observable<BuildingDto> {
    const params = variantIds.map(id => `sectionVariants=${id}`).join('&');

    return this.http.get<BuildingDto>(`${this.gatewayUrl}/${this.apiPrefix}/LayoutSections/building?${params}`);
  }
}
