import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {IMutableContext, UnleashClient} from 'unleash-proxy-client';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private readonly unleash = new UnleashClient({
    url: 'https://unleash-frontend.platforma.build/proxy',
    clientKey: `jdfM45jsdiwCsd`,
    appName: 'platforma',
    environment: environment.stand,
    disableRefresh: true,
    disableMetrics: true,
  });

  isReady(): boolean {
    return this.unleash.isReady();
  }

  start(): Promise<void> {
    this.unleash.start();

    return new Promise((resolve, _reject) => {
      const intervalId = setInterval(() => {
        if (this.unleash.isReady()) {
          clearInterval(intervalId);
          resolve();
        }
      }, 100);
    });
  }

  stop(): void {
    this.unleash.stop();
  }

  updateContext(contextObj: IMutableContext): void {
    this.unleash.updateContext(contextObj);
  }

  isEnabled(feature: string): boolean {
    return this.unleash.isEnabled(feature);
  }
}
