import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {API_URL_PREFIX} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class OmarApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getSpecification(generationVariantId: string, configuration: string): Observable<Blob> {
    return this.http.post(
      `${this.gatewayUrl}/${this.apiPrefix}/Omar/${generationVariantId}/specification`,
      configuration,
      {
        responseType: 'blob',
        headers: {'Content-type': 'application/json'},
      },
    );
  }
}
