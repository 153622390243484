import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {environment} from '@env/environment';
import {ymInit} from '@shared/util';
import {FeatureFlagService} from '@util/unleash.service';
import {EventTypes, OidcSecurityService, PublicEventsService} from 'angular-auth-oidc-client';
import {combineLatest, filter, map, switchMap} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly publicEventsService = inject(PublicEventsService);
  private readonly ff = inject(FeatureFlagService);

  /** На тот случай когда auth лежит с 503 ошибкой будет хотя бы какой-то экран */
  readonly authConfigLoadingFailed$ = this.publicEventsService
    .registerForEvents()
    .pipe(map(event => event.type === EventTypes.ConfigLoadingFailed));

  constructor() {
    /**
     * Старт проверки авторизационного флоу.
     * Забирает токен/код из текущего урла и пишет в стор.
     * Oidc-клиент не делает этого автоматически без этого метода.
     */
    this.oidcSecurityService.checkAuth().subscribe();

    this.oidcSecurityService.isAuthenticated$
      .pipe(
        filter(authResult => authResult.isAuthenticated),
        switchMap(() =>
          combineLatest([
            this.oidcSecurityService.userData$.pipe(filter(userDataResult => userDataResult.userData !== null)),
            this.oidcSecurityService.getPayloadFromAccessToken(),
          ]),
        ),
      )
      .subscribe(([{userData}, _tokenPayload]) => {
        /**
         * tokenPayload – необходим для получения информации не попавшей
         * в userData. Такую как роли занесённые в кейклок
         */

        this.initFF(userData.email);

        ymInit({
          webvisor: environment.metrikaWebvisor,
          userParams: {
            UserId: userData.sub,
            UserName: userData.name,
          },
        });
      });
  }

  private async initFF(email: string): Promise<void> {
    this.ff.updateContext({
      userId: email,
    });

    await this.ff.start();
  }
}
