import {css, html, LitElement} from 'lit';

export class Toolbar extends LitElement {
  static override styles = css`
    :host {
      display: flex;
      width: min-content;
      background-color: #fff;
      border-radius: 0.25rem;
      padding: 0.25rem;
      gap: 0.25rem;
      box-shadow: 0 0 20px 2px #0003;
    }

    ::slotted(bim-toolbar-section:not(:last-child)) {
      border-right: 1px solid var(--bim-ui_bg-contrast-20);
      border-bottom: none;
    }
  `;

  protected override render() {
    return html`
      <slot></slot>
    `;
  }
}
