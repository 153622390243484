import {
  BoundingBoxer,
  Clipper,
  Components,
  FragmentsManager,
  IfcLoader,
  IfcRelationsIndexer,
  SimpleCamera,
  SimpleGrid,
  SimpleScene,
  SimpleWorld,
  Worlds,
} from '@thatopen/components';
import {ClipEdges, EdgesPlane, Highlighter, Outliner, PostproductionRenderer} from '@thatopen/components-front';
import {FragmentsGroup} from '@thatopen/fragments';
import {
  Button,
  Component,
  html,
  Manager,
  Table,
  TableCellValue,
  TableRowData,
  TextInput,
  UpdateFunction,
} from '@thatopen/ui';
import {tables} from '@thatopen/ui-obc';
import {AmbientLight, Color, DirectionalLight, LineBasicMaterial, MeshBasicMaterial} from 'three';
import {IFCSPACE} from 'web-ifc';

import {CustomClassifier, CustomExploder} from './custom-components';
import * as CustomUI from './ui';

customElements.define('ntc-ifc-toolbar', CustomUI.Toolbar);
customElements.define('ntc-ifc-button', CustomUI.Button);
customElements.define('ntc-ifc-toggle-button', CustomUI.ToggleButton);

export class IFCViewer {
  private readonly hostElement: HTMLElement | null = null;
  private readonly canvasElement: HTMLCanvasElement | null = null;

  private readonly components: Components = new Components();

  private world: SimpleWorld<SimpleScene, SimpleCamera, PostproductionRenderer> | null = null;
  private boundingBoxer: BoundingBoxer | null = null;
  private ifcLoader: IfcLoader | null = null;

  private readonly elementPropertiesTable: any;

  private readonly relationsTreeTable = tables.relationsTree({
    components: this.components,
    models: [],
  });

  private readonly relationsTree = this.relationsTreeTable[0];
  private readonly updateRelationsTree = this.relationsTreeTable[1];

  private propertiesTable: Table<TableRowData<Record<string, TableCellValue>>>;
  private updatePropertiesTable: UpdateFunction<any>;

  private readonly onViewerInit: () => void;

  private propertiesPanel: Component;
  private threePanel: Component;

  constructor(hostElement: HTMLElement, canvasElement: HTMLCanvasElement, initCallback?: () => void) {
    this.hostElement = hostElement;
    this.canvasElement = canvasElement;
    this.onViewerInit = initCallback ?? (() => null);
    this.components.init();

    this.initialize()
      .then(() => {
        this.onViewerInit();
      })
      .catch(error => {
        console.warn('Не удалось проинициализировать просмотр', error);
      });
  }

  async load(ifcFile: Blob): Promise<void> {
    // TODO: Вывести сообщение о загрузке на экран
    try {
      /** Выдергиваем из блоба буффер */
      const arrayBuffer = await ifcFile.arrayBuffer();
      /** Создаём целочисленный массив {@link Uint8Array} из {@link ArrayBuffer} */
      const unit8Array = new Uint8Array(arrayBuffer);
      /**
       * Прогоняем полученный массив через webIFC загрузчик и через библиотечную функцию
       * которая превращает IFC в набор Fragment-ов {@link FragmentsGroup}.
       *
       * Каждый фрагмент это обертка над стандартной группой объектов(мешей/фигур) из threejs
       */
      const fragments = await this.ifcLoader.load(unit8Array, true);

      /**
       * Добавляем полученные фрагменты на сцену
       */
      await this.addFragmentsGroupToScene(fragments);

      // TODO: Убрать сообщение о загрузке
    } catch (error: unknown) {
      // TODO: Вывести сообщение об ошибке на экран
    }
  }

  disposeAll(): void {
    this.components.dispose();
  }

  disposeModel(): void {
    // this.components.dispose();
  }

  /** Инициализация всего компонента просмотрщика */
  private async initialize(): Promise<void> {
    this.ifcLoader = new IfcLoader(this.components);
    this.boundingBoxer = new BoundingBoxer(this.components);

    await this.initializeWorld();
    await this.initializeHightligher();
    await this.setupClipper();
    this.createGrid();
    Manager.init();

    this.relationsTree.preserveStructureOnFilter = true;

    await this.ifcLoader.setup({
      webIfc: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        CIRCLE_SEGMENTS: 100,
      },
      autoSetWasm: false,
    });

    /** Убираем из лоудера элементы не влияющие на отображение */
    const excludedCats: number[] = [IFCSPACE];

    for (const cat of excludedCats) {
      this.ifcLoader.settings.excludedCategories.add(cat);
    }

    this.initializeListeners();
    this.initializeToolbar();
  }

  private async initializeWorld(): Promise<void> {
    /** Создание отдельного «мира» для группировки элементов */
    this.world = this.components.get(Worlds).create();

    /** Создаём в новом «мире» сцену */
    this.world.scene = await this.createScene();

    /** Добавялем на сцену рендер-компонент */
    this.world.renderer = new PostproductionRenderer(
      this.components, // Указываем привязку к компонентам данного класса
      this.hostElement, // Элемент родительского контейнера
      {
        canvas: this.canvasElement, // Элемент канваса внутри родительского контейнера
        alpha: true, // прозрачность самого канваса
      },
    );

    /**
     * Добавляем в мир основную камеру.
     *
     * Требует для инициализации существующую Сцену и добавленный Renderer
     */
    this.world.camera = new SimpleCamera(this.components);

    /** Включаем для мира пост-продакшн для улучшения качества картинки */
    this.world.renderer.postproduction.enabled = true;
  }

  /** Запуск работы подсветки элементов и настройка внешнего вида свечения */
  private async initializeHightligher(): Promise<void> {
    const outliner = this.components.get(Outliner);
    const highlighter = this.components.get(Highlighter);

    highlighter.setup({world: this.world});

    outliner.world = this.world;
    outliner.enabled = true;
    outliner.create(
      'outliner',
      new MeshBasicMaterial({
        color: 0xbcf124,
        transparent: true,
        opacity: 0.5,
      }),
    );
  }

  private initializeListeners(): void {
    const outliner = this.components.get(Outliner);
    const highlighter = this.components.get(Highlighter);
    const clipper = this.components.get(Clipper);

    highlighter.events.select.onHighlight.add(fragmentIdMap => {
      outliner.clear('outliner');
      outliner.add('outliner', fragmentIdMap);

      if (this.propertiesPanel?.isConnected) {
        this.updatePropertiesTable({fragmentIdMap});
      }
    });

    highlighter.events.select.onClear.add(() => {
      outliner.clear('outliner');

      if (this.propertiesPanel?.isConnected) {
        this.updatePropertiesTable({fragmentIdMap: {}});
      }
    });

    this.hostElement.ondblclick = () => {
      if (clipper.enabled) {
        clipper.create(this.world);
      }
    };
  }

  // eslint-disable-next-line max-statements
  private async setupClipper(): Promise<void> {
    const edges = this.components.get(ClipEdges);
    const clipper = this.components.get(Clipper);

    clipper.setup();
    clipper.Type = EdgesPlane;

    const grayFill = new MeshBasicMaterial({color: 'gray', side: 2});
    const blackLine = new LineBasicMaterial({color: 'black'});
    const blackOutline = new MeshBasicMaterial({
      color: 'black',
      opacity: 0.5,
      side: 2,
      transparent: true,
    });

    edges.styles.create('thick', new Set(), this.world, blackLine, grayFill, blackOutline);
    edges.styles.create('thin', new Set(), this.world);
  }

  private async updateClipper(): Promise<void> {
    const classifier = this.components.get(CustomClassifier);
    const fragmentsManager = this.components.get(FragmentsManager);
    const edges = this.components.get(ClipEdges);

    const thickItems = classifier.find({
      entities: ['IFCWALLSTANDARDCASE', 'IFCWALL', 'IFCSLAB'],
    });

    const thinItems = classifier.find({
      entities: ['IFCDOOR', 'IFCWINDOW', 'IFCPLATE', 'IFCMEMBER'],
    });

    for (const fragID in thickItems) {
      const foundFrag = fragmentsManager.list.get(fragID);

      if (!foundFrag) {
        continue;
      }

      edges.styles.list.thick.fragments[fragID] = new Set(thickItems[fragID]);
      edges.styles.list.thick.meshes.add(foundFrag.mesh);
    }

    for (const fragID in thinItems) {
      const foundFrag = fragmentsManager.list.get(fragID);

      if (!foundFrag) {
        continue;
      }

      edges.styles.list.thin.fragments[fragID] = new Set(thinItems[fragID]);
      edges.styles.list.thin.meshes.add(foundFrag.mesh);
    }

    await edges.update(true);
  }

  private createGrid(): void {
    const grid = new SimpleGrid(this.components, this.world);

    grid.setup({color: new Color('#000')});
    this.world.renderer.postproduction.customEffects.excludedMeshes.push(grid.three);
  }

  private initializeToolbar(): void {
    const toolbar = Component.create<CustomUI.Toolbar>(() => {
      return html`
        <ntc-ifc-toolbar>
          <ntc-ifc-toggle-button
            icon="lucide:scissors"
            tooltipTitle="Создать секущую плоскость"
            @togglechecked=${(ev: CustomUI.ToggleButtonCheckedEvent) => {
              this.onClipperButtonClick(ev.detail.value);
            }}
          ></ntc-ifc-toggle-button>

          <ntc-ifc-button
            icon="lucide:fullscreen"
            tooltipTitle="Отцентровать"
            @click="${this.onZoomButtonClick.bind(this)}"
          ></ntc-ifc-button>

          <ntc-ifc-toggle-button
            icon="lucide:unfold-vertical"
            tooltipTitle="Взрыв-схема"
            @togglechecked=${(ev: CustomUI.ToggleButtonCheckedEvent) => {
              this.onExplodeButtonClick(ev.detail.value);
            }}
          ></ntc-ifc-toggle-button>
          <ntc-ifc-toggle-button
            icon="lucide:list-tree"
            tooltipTitle="Дерево свойств модели"
            @togglechecked=${(ev: CustomUI.ToggleButtonCheckedEvent) => {
              this.onThreeButtonClick(ev.detail.value);
            }}
          ></ntc-ifc-toggle-button>
          <ntc-ifc-toggle-button
            icon="lucide:notebook-text"
            tooltipTitle="Описание"
            @togglechecked=${(ev: CustomUI.ToggleButtonCheckedEvent) => {
              this.onPropertiesButtonClick(ev.detail.value);
            }}
          ></ntc-ifc-toggle-button>
        </ntc-ifc-toolbar>
      `;
    });

    this.hostElement.getElementsByClassName('toolbar')[0].appendChild(toolbar);
    this.hostElement.getElementsByClassName('toolbar')[0].setAttribute('style', 'bottom: 2rem');

    [this.propertiesTable, this.updatePropertiesTable] = tables.elementProperties({
      components: this.components,
      fragmentIdMap: {},
    });
    this.propertiesTable.preserveStructureOnFilter = true;
    this.propertiesTable.indentationInText = false;

    this.propertiesPanel = Component.create(() => {
      const onTextInput = (e: Event) => {
        const input = e.target as TextInput;

        this.propertiesTable.queryString = input.value !== '' ? input.value : null;
      };

      const expandTable = (e: Event) => {
        const button = e.target as Button;

        this.propertiesTable.expanded = !this.propertiesTable.expanded;
        button.label = this.propertiesTable.expanded ? 'Collapse' : 'Expand';
      };

      const copyAsTSV = async () => {
        await navigator.clipboard.writeText(this.propertiesTable.tsv);
      };

      return html`
        <bim-panel
          label="Properties"
          style="position: absolute; top: 1rem; left: 1rem; bottom: 1rem; width: 25vw; min-width: 20rem; max-width: 30rem;"
        >
          <bim-panel-section label="Element Data">
            <div style="display: flex; gap: 0.5rem;">
              <bim-button
                label=${this.propertiesTable?.expanded ? 'Collapse' : 'Expand'}
                @click=${expandTable}
              ></bim-button>
              <bim-button label="Copy as TSV" @click=${copyAsTSV}></bim-button>
            </div>
            <bim-text-input placeholder="Search Property" debounce="250" @input=${onTextInput}></bim-text-input>
            ${this.propertiesTable}
          </bim-panel-section>
        </bim-panel>
      `;
    });

    this.threePanel = Component.create(() => {
      const onSearch = (e: Event) => {
        const input = e.target as TextInput;

        this.relationsTree.queryString = input.value;
      };

      return html`
        <bim-panel
          label="Relations Tree"
          style="position: absolute; top: 1rem; left: 1rem; bottom: 1rem; width: 25vw; min-width: 20rem; max-width: 30rem;"
        >
          <bim-panel-section label="Model Tree">
            <bim-text-input placeholder="Search..." debounce="200" @input=${onSearch}></bim-text-input>
            ${this.relationsTree}
          </bim-panel-section>
        </bim-panel>
      `;
    });
  }

  private onClipperButtonClick(enable: boolean): void {
    const clipper = this.components.get(Clipper);

    clipper.enabled = enable;
    clipper.deleteAll();
  }

  private onPropertiesButtonClick(enable: boolean): void {
    if (enable) {
      this.hostElement.appendChild(this.propertiesPanel);
    } else {
      this.hostElement.removeChild(this.propertiesPanel);
    }
  }

  private onThreeButtonClick(enable: boolean): void {
    if (enable) {
      this.hostElement.appendChild(this.threePanel);
    } else {
      this.hostElement.removeChild(this.threePanel);
    }
  }

  private onExplodeButtonClick(enable: boolean): void {
    this.components.get(CustomExploder).set(enable);
    this.components.get(Highlighter).clear();
  }

  private onZoomButtonClick(): void {
    this.world.camera.controls.fitToSphere(this.boundingBoxer.getMesh(), true);
  }

  /** Создание компонента простой сцены, библиотеки openbim, для работы со сценой threejs */
  private async createScene(): Promise<SimpleScene> {
    const scene = new SimpleScene(this.components);

    await scene.setup({
      backgroundColor: new Color('#fff'),
      ambientLight: new AmbientLight('white', 2),
      directionalLight: new DirectionalLight('white', 5),
    });

    return scene;
  }

  /**
   * Добавление группы фрагментов на сцену и обновление всех зависящих от них компонентов
   */
  private async addFragmentsGroupToScene(fragmentsGroup: FragmentsGroup): Promise<void> {
    /** Добавляем на сцену */
    this.world.scene.three.add(fragmentsGroup);
    /** Добавляем в BoundingBoxer, чтобы правильно расчитывался объем всех объектов на сцене */
    this.boundingBoxer.add(fragmentsGroup);

    /**
     * Каждый оригинальный мэш(фигура/3д-объект) добавляем в мир, чтобы с ними мог взаимодействовать рейкастер.
     *
     * Не будет мешей в мире – рейкастер будет стрелять сквозь фрагменты
     */
    for (const mesh of this.components.get(FragmentsManager).meshes) {
      this.world.meshes.add(mesh);
    }

    /** Запускаем процесс индексации всех дочерних фрагментов внутри группы */
    await this.components.get(IfcRelationsIndexer).process(fragmentsGroup);
    /** Разбиваем все фрагменты на группы для работы эксплоудера (для него объекты группируются поэтажно) */
    await this.components.get(CustomClassifier).bySpatialStructure(fragmentsGroup);
    await this.components.get(CustomClassifier).byEntity(fragmentsGroup);

    await this.updateClipper();
    this.world.camera.controls.fitToSphere(this.boundingBoxer.getMesh(), true);
  }
}
