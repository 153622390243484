import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {AppNavpanelComponent} from '@layout/ui/components';
import {FeatureFlagService} from '@util/unleash.service';

@Component({
  standalone: true,
  imports: [RouterOutlet, AppNavpanelComponent],
  selector: 'layout-default',
  templateUrl: './default.component.html',
  styleUrl: './default.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Компонент дефолтной обертки для контента.
 *
 * Состоит из боковой панели и контентной области в 100% высоты экрана.
 * Содержимое контентной области обрезается рамками бразера. Области
 * скролла необходимо настраивать в shell-компонентах ваших фичей.
 */
export class LayoutDefaultComponent {
  private readonly ff = inject(FeatureFlagService);

  readonly happyNewYearFF = this.ff.isEnabled('happy-new-year');
}
