import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {ResultPartDto} from '@common/interfaces/result-part.dto';
import {ArtefactsType} from '@common/types';
import {API_URL_PREFIX, ModelInfoDto, ShortModelInfoDto, StructureVariantsGetAllParams} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {BuildOptionsDto} from '@structure-api/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class StructureApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getResource(
    generationVariantId: string,
    artefactsType?: ArtefactsType,
    isAnalyticalModel?: boolean,
  ): Observable<Blob> {
    const params: {artefactsType?: ArtefactsType; isAnalyticalModel?: boolean} = {
      isAnalyticalModel,
      artefactsType,
    };

    return this.http.get(`${this.gatewayUrl}/${this.apiPrefix}/Structure/${generationVariantId}/resource`, {
      params: {
        ...params,
      },
      responseType: 'blob',
    });
  }

  getVariants(
    generationTaskId: string,
    rawParams?: StructureVariantsGetAllParams,
  ): Observable<ResultPartDto<ShortModelInfoDto>> {
    let params = {};

    if (rawParams) {
      params = rawParams;
    }

    return this.http.get<ResultPartDto<ShortModelInfoDto>>(
      `${this.gatewayUrl}/${this.apiPrefix}/Structure/${generationTaskId}/variants/info`,
      {
        params,
      },
    );
  }

  getVariantModelInfo(generationVariantId: string): Observable<ModelInfoDto> {
    return this.http.get<ModelInfoDto>(`${this.gatewayUrl}/${this.apiPrefix}/Structure/${generationVariantId}/info`);
  }

  /** Возвращает конфигурацию построения всей объектной модели. */
  getSettings(): Observable<BuildOptionsDto> {
    return this.http.get<BuildOptionsDto>(`${this.gatewayUrl}/${this.apiPrefix}/Structure/settings-build-options`);
  }
}
