import {GenerationTaskSubsystemType} from '@generation-api-v2/util';

import {SubsystemBreadcrumbsLink} from './components/subsystem-breadcrumbs-link/subsystem-breadcrumbs-link';

export const initialData: SubsystemBreadcrumbsLink[] = [
  {
    subsystem: GenerationTaskSubsystemType.Layout,
  },
  {
    subsystem: GenerationTaskSubsystemType.SpacePlanning,
  },
  {
    subsystem: GenerationTaskSubsystemType.OMAR,
  },
  {
    subsystem: GenerationTaskSubsystemType.Structure,
  },
  {
    subsystem: GenerationTaskSubsystemType.OMAR2,
  },
  {
    subsystem: GenerationTaskSubsystemType.Plumbing,
  },
  {
    subsystem: GenerationTaskSubsystemType.Electrical,
  },
  {
    subsystem: GenerationTaskSubsystemType.HVAC,
  },
  {
    subsystem: GenerationTaskSubsystemType.Plumbing2,
  },
  {
    subsystem: GenerationTaskSubsystemType.HVAC2,
  },
  {
    subsystem: GenerationTaskSubsystemType.Electrical2,
  },
  {
    subsystem: GenerationTaskSubsystemType.OMAR3,
  },
  {
    subsystem: GenerationTaskSubsystemType.Structure2,
  },
  {
    subsystem: GenerationTaskSubsystemType.Final,
  },
];
