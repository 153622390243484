import 'iconify-icon';

import {css, html, LitElement} from 'lit';
import {property} from 'lit/decorators.js';

export type ToggleButtonCheckedEvent = CustomEvent<{value: boolean}>;
const toggleButtonChecked = (value: boolean): ToggleButtonCheckedEvent =>
  new CustomEvent('togglechecked', {
    detail: {value},
    bubbles: true,
    composed: true,
  });

export class ToggleButton extends LitElement {
  static override styles = css`
    .real-button {
      --ntc-button-size: 2.75rem;
      --ntc-button-radius: 0.25rem;
      --ntc-button-gap: 0.25rem;
      --ntc-button-padding: 0.825rem;

      position: relative;

      display: inline-flex;
      align-items: center;
      justify-content: center;

      appearance: none;
      padding: 0;
      border: 0;
      background-color: #f6f6f6;
      font-size: inherit;
      line-height: inherit;
      text-decoration: none;

      flex-shrink: 0;
      box-sizing: border-box;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: middle;
      max-inline-size: 100%;
      gap: 0.25rem;
      block-size: var(--ntc-button-size);
      border-radius: var(--ntc-button-radius);
      padding: var(--ntc-button-padding);
      user-select: none;
      color: #333333;
      font: inherit;
      font-weight: 700;
      cursor: pointer;
    }

    :host(:not([checked]):hover) .real-button,
    :host(:not([checked]):active) .real-button,
    :host(:not([checked])[active]) .real-button {
      background-color: #ededed;
    }

    :host([checked]) .real-button,
    :host([checked]) .real-button,
    :host([active][checked]) .real-button {
      background-color: #2a61ed;
      color: #fff;
    }

    :host([disabled]) .real-button {
      background-color: gray;
      cursor: default;
    }

    .tooltip {
      position: absolute;
      padding: 0.75rem;
      z-index: 99;
      display: flex;
      flex-flow: column;
      row-gap: 0.375rem;
      box-shadow: 0 0 10px 3px rgba(0 0 0 / 20%);
      outline: 1px solid var(--bim-ui_bg-contrast-40);
      font-size: var(--bim-ui_size-xs);
      border-radius: var(--bim-ui_size-4xs);
      background-color: var(--bim-ui_bg-contrast-20);
      color: var(--bim-ui_bg-contrast-100);
    }

    :host(:not([tooltip-visible])) .tooltip {
      display: none;
    }

    iconify-icon {
      height: 1.25rem;
      width: 1.25rem;
      color: inherit;
      transition: color 0.15s;
    }
  `;

  /**
   * The label to be displayed on the button.
   * @type {Boolean}
   * @default false
   * @example <ntc-ifc-toggle-button label="Click me"></ntc-ifc-toggle-button>
   */
  @property({type: Boolean, reflect: true, attribute: 'checked'})
  checked = false;

  /**
   * The label to be displayed on the button.
   * @type {string}
   * @default undefined
   * @example <ntc-ifc-toggle-button label="Click me"></ntc-ifc-toggle-button>
   */
  @property({type: String, reflect: true})
  label?: string;

  /**
   * A boolean attribute which, if present, indicates that the button is disabled.
   * @default false
   * @example <ntc-ifc-toggle-button label="Click me" disabled></ntc-ifc-toggle-button>
   */
  @property({type: Boolean, reflect: true, attribute: 'disabled'})
  disabled = false;

  /**
   * The icon to be displayed on the button.
   * @type {string}
   * @default undefined
   * @example <ntc-ifc-toggle-button icon="my-icon"></ntc-ifc-toggle-button>
   */
  @property({type: String, reflect: true})
  icon?: string;

  protected override render() {
    return html`
      <button class="real-button" @click=${this.onClick}>
        ${this.label || this.icon
          ? html`
              <iconify-icon .icon=${this.icon} height="none"></iconify-icon>
            `
          : null}
      </button>
    `;
  }

  private onClick(event: MouseEvent): void {
    event.stopPropagation();

    this.checked = !this.checked;
    this.requestUpdate();

    this.dispatchEvent(toggleButtonChecked(this.checked));
    super.click();
  }
}
