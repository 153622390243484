import 'iconify-icon';

import {computePosition, flip, inline, offset, shift} from '@floating-ui/dom';
import {css, html, LitElement} from 'lit';
import {property} from 'lit/decorators.js';
import {createRef, ref} from 'lit/directives/ref.js';

/**
 * A custom button web component for BIM applications. HTML tag: bim-button
 *
 * @fires click - Fired when the button is clicked.
 */
export class Button extends LitElement {
  static override styles = css`
    :host {
      --ntc-button-size: 2.75rem;
      --ntc-button-radius: 0.25rem;
      --ntc-button-gap: 0.25rem;
      --ntc-button-padding: 0.825rem;

      position: relative;

      display: inline-flex;
      align-items: center;
      justify-content: center;

      appearance: none;
      padding: 0;
      border: 0;
      background-color: #f6f6f6;
      font-size: inherit;
      line-height: inherit;
      text-decoration: none;

      flex-shrink: 0;
      box-sizing: border-box;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: middle;
      max-inline-size: 100%;
      gap: 0.25rem;
      block-size: var(--ntc-button-size);
      border-radius: var(--ntc-button-radius);
      padding: var(--ntc-button-padding);
      user-select: none;

      font: inherit;
      font-weight: 700;
      cursor: pointer;
    }

    :host(:hover),
    :host(:active),
    :host[active] {
      background-color: #ededed;
    }

    :host([disabled]) {
      background-color: gray;
      cursor: default;
    }

    .tooltip {
      position: absolute;
      padding: 0.75rem;
      z-index: 99;
      display: flex;
      flex-flow: column;
      row-gap: 0.375rem;
      box-shadow: 0 0 10px 3px rgba(0 0 0 / 20%);
      outline: 1px solid var(--bim-ui_bg-contrast-40);
      font-size: var(--bim-ui_size-xs);
      border-radius: var(--bim-ui_size-4xs);
      background-color: var(--bim-ui_bg-contrast-20);
      color: var(--bim-ui_bg-contrast-100);
    }

    :host(:not([tooltip-visible])) .tooltip {
      display: none;
    }

    iconify-icon {
      height: 1.25rem;
      width: 1.25rem;
      color: #333333;
      transition: color 0.15s;
    }
  `;

  private readonly parent = createRef<HTMLDivElement>();
  private readonly tooltip = createRef<HTMLDivElement>();

  private timeoutID?: number;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _mouseLeave = false;

  /**
   * The label to be displayed on the button.
   * @type {string}
   * @default undefined
   * @example <bim-button label="Click me"></bim-button>
   * @example const button = document.createElement('bim-button');
   *          button.label = 'Click me';
   */
  @property({type: String, reflect: true})
  label?: string;

  /**
   * A boolean attribute which, if present, indicates that the button is disabled.
   * @default false
   * @example <bim-button label="Click me" disabled></bim-button>
   * @example const button = document.createElement('bim-button');
   *          button.label = 'Click me';
   *          button.disabled = true;
   */
  @property({type: Boolean, reflect: true, attribute: 'disabled'})
  disabled = false;

  /**
   * The icon to be displayed on the button.
   * @type {string}
   * @default undefined
   * @example <bim-button icon="my-icon"></bim-button>
   * @example const button = document.createElement('bim-button');
   *          button.icon = 'my-icon';
   */
  @property({type: String, reflect: true})
  icon?: string;

  /**
   * The time (in milliseconds) to wait before showing the tooltip when hovering over the button.
   * @type {number}
   * @default 700
   * @example <bim-button label="Click me" tooltip-time="1000"></bim-button>
   * @example const button = document.createElement('bim-button');
   *          button.label = 'Click me';
   *          button.tooltipTime = 1000;
   */
  @property({type: Number, attribute: 'tooltip-time', reflect: true})
  tooltipTime?: number;

  /**
   * A boolean attribute which, if present, indicates that the tooltip should be visible.
   * @default false
   * @example <bim-button label="Click me" tooltip-visible></bim-button>
   * @example const button = document.createElement('bim-button');
   *          button.label = 'Click me';
   *          button.tooltipVisible = true;
   */
  @property({type: Boolean, attribute: 'tooltip-visible', reflect: true})
  tooltipVisible = false;

  /**
   * The title of the tooltip to be displayed when hovering over the button.
   * @type {string}
   * @default undefined
   * @example <bim-button label="Click me" tooltip-title="Button Tooltip"></bim-button>
   * @example const button = document.createElement('bim-button');
   *          button.label = 'Click me';
   *          button.tooltipTitle = 'Button Tooltip';
   */
  @property({type: String, attribute: 'tooltip-title', reflect: true})
  tooltipTitle?: string;

  /**
   * The text of the tooltip to be displayed when hovering over the button.
   * @type {string}
   * @default undefined
   * @example <bim-button label="Click me" tooltip-text="This is a tooltip"></bim-button>
   * @example const button = document.createElement('bim-button');
   *          button.label = 'Click me';
   *          button.tooltipText = 'This is a tooltip';
   */
  @property({type: String, attribute: 'tooltip-text', reflect: true})
  tooltipText?: string;

  private set mouseLeave(value: boolean) {
    this._mouseLeave = value;

    if (value) {
      this.tooltipVisible = false;
      clearTimeout(this.timeoutID);
    }
  }

  private get mouseLeave() {
    return this._mouseLeave;
  }

  constructor() {
    super();
    this.mouseLeave = true;
  }

  override click() {
    if (!this.disabled) {
      super.click();
    }
  }

  override firstUpdated() {
    this.addEventListener('onmouseenter', this.onMouseEnter);
  }

  protected override render() {
    const tooltipTemplate = html`
      <div ${ref(this.tooltip)} class="tooltip">
        ${this.tooltipTitle
          ? html`
              <p style="text-wrap: nowrap;">
                <strong>${this.tooltipTitle}</strong>
              </p>
            `
          : null}
        ${this.tooltipText
          ? html`
              <p style="width: 9rem;">${this.tooltipText}</p>
            `
          : null}
      </div>
    `;

    return html`
      ${this.label || this.icon
        ? html`
            <iconify-icon .icon=${this.icon} height="none"></iconify-icon>
          `
        : null}
      ${this.tooltipTitle || this.tooltipText ? tooltipTemplate : null}
    `;
  }

  private async computeTooltipPosition() {
    const {value: parent} = this.parent;
    const {value: tooltip} = this.tooltip;

    if (!(parent && tooltip)) {
      return;
    }

    await computePosition(parent, tooltip, {
      placement: 'bottom',
      middleware: [offset(10), inline(), flip(), shift({padding: 5})],
    }).then((data: any) => {
      const {x, y} = data;

      Object.assign(tooltip.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  private onMouseEnter() {
    if (!(this.tooltipTitle || this.tooltipText)) {
      return;
    }

    this.mouseLeave = false;
    const tooltipTime = this.tooltipTime ?? 700;

    this.timeoutID = setTimeout(async () => {
      if (this.mouseLeave) {
        return;
      }

      await this.computeTooltipPosition();
      this.tooltipVisible = true;
    }, tooltipTime) as unknown as number;
  }
}
